<template>
  <v-select
    v-model="value"
    :items="items"
    filled
    chips
    :disabled="disabled"
    :item-text="itemtext"
    color="primary-color"
    :item-value="itemvalue"
    class="login-email-input"
    :prepend-inner-icon="icon"
    :label="placeholder"
    hide-details
    @blur="$emit('blur')"
  ></v-select>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemtext: {
      type: String,
      default: null
    },
    itemvalue: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    default: {
      required: false
    }
  },
  data() {
    return {
      value: []
    }
  },
  created() {
    if (this.default) {
      this.value = this.default;
    }
  },
  watch: {
    value() {
      this.$emit('value', this.value);
    },
    default() {
      this.value = this.default;
    }
  }
}
</script>

<style scoped>
.login-email-input {
  width: 100%;
}
</style>