<template>
  <v-container fluid class="px-4 mb-4 pt-0 mt-0">
    <v-stepper class="secondary-color" :value="value" non-linear>
      <v-stepper-header>
        <v-stepper-step
          editable
          step="1"
          :edit-icon="null"
          @click="() => { $route.name != 'bases-administrativas' ? $router.push('/bases-administrativas') : '' }"
          color="primary-color"
        >
          <strong class="white--text">Bases Administrativas</strong>
        </v-stepper-step>
        <v-divider class="tertiary-color"></v-divider>
        <v-stepper-step
          editable
          step="2"
          :edit-icon="null"
          @click="$route.name != 'ficha-plan-operativo' ? $router.push('/ficha-plan-operativo') : ''"
          color="primary-color"
        >
          <strong class="white--text">Ficha de Plan Operativo</strong>
        </v-stepper-step>

        <v-divider class="tertiary-color"></v-divider>

        <v-stepper-step
          editable
          step="3"
          :edit-icon="null"
          @click="$route.name != 'formularios-ejecucion' ? $router.push('/formularios-ejecucion') : ''"
          color="primary-color"
        >
          <strong class="white--text">Formularios de ejecución</strong>
        </v-stepper-step>
        <v-divider class="tertiary-color"></v-divider>
        <v-stepper-step
            editable
            step="4"
            :edit-icon="null"
            @click="$route.name !== 'seguimiento-evaluacion' ? $router.push('/seguimiento-evaluacion') : ''"
            color="primary-color">
          <strong class="white--text">Seguimiento y Evaluación</strong>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      value: 0,
    }
  },
  created() {
    if (this.$route.name == 'bases-administrativas') {
      this.value = 1;
    }
    if (this.$route.name == 'ficha-plan-operativo') {
      this.value = 2;
    }
    if (this.$route.name == 'formularios-ejecucion') {
      this.value = 3;
    }
    if (this.$route.name == 'seguimiento-evaluacion') {
      this.value = 4;
    }
  },
}
</script>
