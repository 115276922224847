<template>
  <div class="pt-15 my-10 main-component-content">
    <steps-component/>
    <v-row class="ma-0 pa-0">
      <v-col
        :class="mobile ? 'border-t' : 'border-r'"
        cols="12"
        xs="12"
        sm="12"
        md="8"
        lg="8"
        xl="8"
      >
        <v-container>
          <v-col class="mb-8 mt-0 mx-0 pa-0" align-self="start">
            <h1 class="text-left w-full mb-2">Formularios de ejecución</h1>
            <p class="text-left w-full">
              Puede descargar los formularios solicitados por la Unidad de Coordinación Institucional (UCI), para el uso de su presupuesto y la solicitud de reitemización
            </p>
            <v-container fluid class="content">
              <v-row justify="space-between" align="center" class="pa-0 ma-0 mb-2">
                <span class="mr-2">Seleccione un documento</span>
              </v-row>
              <v-row align="center" class="ma-0 pa-0">
                <v-col>
                  <select-component
                    placeholder="Seleccione un documento"
                    :items="documents"
                    itemtext="name"
                    icon="mdi-arrow-bottom-right-thin-circle-outline"
                    :default="selector"
                    @value="(value) => { selector = value }"
                  />
                </v-col>
                <v-col>
                  <v-btn
                    class="tertiary-color white--text"
                    width="100%"
                    large
                    @click="downloadFile()"
                  >
                    descargar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-divider></v-divider>
        </v-container>
        <div class="welcome-link-group mb-8">
          <v-btn style="background-color: #EA7730; color: white"
                 dark class="mr-md-1" @click="$router.push('home')">Volver</v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
      >
        <button-component
          v-for="(item, itemIndex) in categories"
          :key="itemIndex"
          class="mb-2 text-transform-none"
          @whenClick="() => { current = item.id }"
          :disabled="item.id === current"
        >
          {{ item.name }}
        </button-component>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Select from '@/components/form/Select.vue';
import Button from '@/components/form/Button.vue';
import StepComponent from '@/components/steps/Stepper.vue';
import { mapActions } from 'vuex';
import Swal from "sweetalert2";

export default {
  components: {
    'button-component': Button,
    'steps-component': StepComponent,
    'select-component': Select,
  },
  data() {
    return {
      categories: [],
      documents: [],
      current: 1,
      selector: {},
    };
  },
  async created() {
      await this.getAllFormsCategory();
      await this.getAllPlatformFormsByCategory(this.current)
          .then(response => {
              this.documents = response.data.response;
              if (this.documents.length != 0) {
                  this.selector = this.documents[0];
              } else {
                  this.selector = {};
              }
          })
          .catch(err => {
              console.log(err);
          });
  },
  methods: {
    ...mapActions('main', [
      'showPlatformFormsFile',
      'getAllPlatformFormsCategory',
        'getAllPlatformFormsByCategory'
    ]),
    downloadFile() {
      if(this.selector) {
        this.showPlatformFormsFile(this.selector.id)
          .then(response => {
            console.log(response.data.response);
              this.download(response.data.response);
          })
          .catch(err => {
              console.log(err);
              Swal.fire({
                  title: 'Descarga Formulario',
                  text: err,
                  confirmButtonColor: '#EA7600',
                  confirmButtonText: 'Aceptar',
                  icon: 'warning',
              });
          });
      }
    },
      download(data){
          const {file, filename, mime} = data;
          const linkSource = `data:${mime};base64,${file}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = filename;
          downloadLink.click();
      },
      getAllFormsCategory(){
          this.getAllPlatformFormsCategory().then(response => {
              this.categories = response.data.response;
          }).catch(e => {
              console.log(e);
          });
      }
  },
  watch: {
    current() {
        this.getAllPlatformFormsByCategory(this.current)
            .then(response => {
                this.documents = response.data.response;
                if (this.documents.length != 0) {
                    this.selector = this.documents[0];
                } else {
                    this.selector = {};
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
};
</script>

<style scoped>
.main-component-content {
  min-height: 100vh;
  width: 100%;
}
.welcome-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.welcome-link {
  cursor: pointer;
  text-decoration: underline;
}
.welcome-link:not(:last-child) {
  margin-right: 1em;
}
.border-t {
  border-top: 1px solid rgba(0,0,0,.12);
}
.border-r {
  border-right: 1px solid rgba(0,0,0,.12);
}
.text-transform-none {
  text-transform: none;
}
.home-background-card {
  width: 100%;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
